import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link as NavLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import RoleIcon from '../RoleIcon';

const useStyles = makeStyles(theme => ({
  publishedSuccessfullMessage: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(6, 3, 4, 3),
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(90deg, ${theme.palette.gradients.regular[0]} 0%, ${theme.palette.gradients.regular[1]} 100%)`,
    '& > *': {
      textAlign: 'center'
    }
  },
  headerTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem'
    }
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(2)
    }
  }
}));

const PublishedRegular = props => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.publishedSuccessfullMessage}>
        <Typography
          className={classes.headerTitle}
          variant="h4"
          style={{ fontWeight: 800, color: '#fff', fontSize: '2.5rem' }}
        >
          FELICITACIONES!
        </Typography>
        <Typography variant="h4" style={{ color: '#fff', marginBottom: '8px' }}>
          Recibimos tu producto
        </Typography>
        <Typography
          style={{
            color: '#fff',
            fontWeight: 600,
            textAlign: 'center',
            maxWidth: '420px',
            lineHeight: '1.2',
            fontSize: '1.2rem'
          }}
        >
          En revisión: En las siguientes 48 horas hábiles lo vamos a aprobar y
          publicar en tu vestidor
        </Typography>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="secondary"
            component={NavLink}
            to={`/vestidor/${props.loggedInUser.username}`}
          >
            IR A MI VESTIDOR
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to="/vender"
          >
            SEGUIR PUBLICANDO
          </Button>
        </div>
        <Typography
          style={{
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px'
          }}
        >
          <RoleIcon
            style={{ width: '45px', height: '35px', marginRight: '8px' }}
            roleVariant="ROLE_PREMIUM"
          />
          Convertite en vendedora PREMIUM y accedé a grandes beneficios!
        </Typography>
        <a
          color="secondary"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: '#707070'
          }}
          href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056342772-C%C3%B3mo-me-convierto-en-vendedora-Premium-"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mas info
        </a>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.auth.user
});

export default connect(mapStateToProps)(PublishedRegular);
