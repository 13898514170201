import React, { Component } from 'react';
import lodash from 'lodash';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  withWidth,
  Typography,
  SvgIcon,
  Button,
  Divider
} from '@material-ui/core';
import { fade, withStyles } from '@material-ui/core/styles';
import { batch } from 'react-redux';

import CartPreview from '../CartPreview';
import Footer from '../Footer';
import DesktopBar from './Desktop';
import MobileBar from './Mobile';
import Banners from './Banners';

import { logout } from '../../redux/actions/auth';
import { fetchCategories, fetchBrands } from '../../redux/actions/categories';
import { ReactComponent as HeartBroken } from '../../images/Heart_Roto.svg';
import SustainabilityMeter from '../SustainabilityMeter/SustainabilityMeter';
import DownloadAppBanner from '../DownloadApp/DownloadAppBanner';

const styles = theme => ({
  appContent: ({ match: { path } }) => ({
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      marginTop:
        path !== '/home' && path !== '/producto' && path !== '/productos'
          ? '56px'
          : 0
    }
  }),
  toolbar: {
    background: '#000',
    textAlign: 'center',
    color: '#fff'
  },
  title: {
    flexGrow: 1,
    '& > h5': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  message: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(0, 0, -2, -2),
    cursor: 'pointer'
  },
  liMenu: {
    height: '1rem',
    paddingTop: 0,
    paddingBottom: 0
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    '& > li': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      '& > li': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  submenu: {
    width: 'unset',
    justifyContent: 'center',
    '& > li': {
      paddingLeft: 0,
      paddingRight: 0,
      '& > button': {
        color: '#fff !important'
      }
    }
  },
  notFound: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    minWidth: '200px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    },
    '& .MuiInputBase-root': {
      backgroundColor: fade(theme.palette.common.white, 0.9),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 1)
      },
      borderRadius: '25px'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  infoButton: {
    position: 'absolute',
    padding: theme.spacing(0.25),
    right: 0,
    top: '65px',
    fontSize: '.7rem',
    background: '#000',
    color: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    fontSize: '.8rem'
  },
  mobileToolbar: {
    background: '#1f1f1f',
    color: '#fff',
    '& > div': {
      maxHeight: '55px',
      paddingRight: 0
    }
  },
  mobileMenu: {
    overflow: 'auto',
    background: '#1f1f1f',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    color: '#fff',
    height: '100%',
    width: '250px',
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  drawer: {
    width: '250px'
  },
  mobileRightMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1
  },
  avatarMobile: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '70px',
    height: '70px',
    border: '3px solid #1f1f1f',
    overflow: 'hidden',
    '& > span': {
      '& > div': {
        width: '70px',
        height: '70px'
      }
    }
  },
  searchBar: {
    margin: theme.spacing(1),
    marginTop: `calc(${theme.spacing(1)}px + 55px)`
  },
  inputMobile: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '1rem'
  },
  inputs: {
    '& > fieldset': {
      border: 'none',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
      '&:hover': {
        border: 'none'
      },
      '&:active': {
        border: 'none'
      }
    },
    '&:hover': {
      border: 'none'
    }
  },
  category: {
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      flex: 1
    }
  },
  searchButtons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > button': {
      width: '120px',
      margin: theme.spacing(1),
      color: '#707070',
      border: '2px solid #707070',
      backgroundColor: '#fff',
      borderRadius: theme.shape.borderRadius,
      fontWeight: 500
    }
  },
  activeButton: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`
  },
  sustainabilityContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
    // backgroundColor: '#B5E9D8'
  }
});

const Message = ({
  message,
  classes,
  publishedProduct,
  welcomeBuyerCampaign,
  welcomeSellerCampaign
}) => {
  if (!message) {
    return '';
  }

  if (typeof message === 'string') {
    return (
      <div className={classes.message}>
        <Typography style={{ fontWeight: 500 }}>{message}</Typography>
      </div>
    );
  }

  const MessageComponent = message;

  return (
    <MessageComponent
      publishedProduct={publishedProduct}
      welcomeBuyerCampaign={welcomeBuyerCampaign}
      welcomeSellerCampaign={welcomeSellerCampaign}
    />
  );
};

class AppLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      searchFocused: false,
      searchMode: 'products',
      message: props.message
    };
  }

  componentDidMount() {
    this.props.fetchBrandsAndCategories();
  }

  componentDidUpdate(prevProps) {
    if (!lodash.isEqual(prevProps.message, this.props.message)) {
      this.setState({ message: this.props.message });
    }

    if (
      !lodash.isEqual(prevProps.privateStats, this.props.privateStats) &&
      this.props.user
    ) {
      if (
        this.props.privateStats.roles &&
        Array.isArray(this.props.privateStats.roles)
      ) {
        if (
          this.props.user.roles.includes('ROLE_SHOWROOM_PENDING') &&
          this.props.privateStats.roles.includes('ROLE_SHOWROOM')
        ) {
          this.setState({
            message:
              'Tu Showroom ha sido validado! Puedes empezar a COMPRAR y VENDER!'
          });
          this.props.validateShowroom();
        }
      }
    }
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ search: value });
  };

  focusSearch = focus => {
    this.setState({ searchFocused: focus });
  };

  setSearchMode = mode => {
    this.setState({ searchMode: mode });
  };

  goSearch = () => {
    if (this.state.searchMode === 'products') {
      const findInString = (stringToFind, string) => {
        const regex = new RegExp('\\b' + stringToFind + '\\b', 'i');
        return regex.test(string);
      };
      const filteredBrands = this.props.brands
        .filter(brand => findInString(this.state.search.trim(), brand.name))
        .map(brand => brand.id);
      if (filteredBrands.length > 0 && this.state.search.trim() !== '') {
        this.props.push(
          `/productos?rel&brand=${filteredBrands.join('&brand=')}`
        );
        return;
      }
      this.props.push(
        `/productos?rel&query=${encodeURIComponent(this.state.search)}`
      );
    } else {
      this.props.push(
        `/vestidores?rel&query=${encodeURIComponent(this.state.search)}`
      );
    }
  };

  render() {
    const { classes, shoppingCartPreview } = this.props;
    const path = window.location.pathname;
    const cartPreviewOpen = shoppingCartPreview?.indexOf(path) !== -1;
    let avatar = null;
    if (this.props.privateStats && !this.props.noProfile) {
      avatar = this.props.privateStats.media
        ? this.props.privateStats.media.default_small ||
          this.props.privateStats.media.user_small
        : null;
    }

    const mobileSearchable =
      this.props.match.path === '/home' ||
      this.props.match.path === '/productos' ||
      this.props.match.path === '/producto';

    const mobile = this.props.width === 'xs' || this.props.width === 'sm';
    const Bar = mobile ? MobileBar : DesktopBar;

    let classesForBar = {};
    if (!mobile) {
      classesForBar = {
        appContent: classes.appContent,
        toolbar: classes.toolbar,
        title: classes.title,
        logo: classes.logo,
        liMenu: classes.liMenu,
        menu: classes.menu,
        submenu: classes.submenu,
        navigation: classes.navigation,
        search: classes.search,
        infoButton: classes.infoButton,
        infoIcon: classes.infoIcon,
        typeSelect: classes.typeSelect,
        openPopperField: classes.openPopperField,
        popperPaper: classes.popperPaper
      };
    } else {
      classesForBar = {
        appContent: classes.appContent,
        toolbar: classes.toolbar,
        title: classes.title,
        message: classes.message,
        logo: classes.logo,
        liMenu: classes.liMenu,
        menu: classes.menu,
        submenu: classes.submenu,
        notFound: classes.notFound,
        navigation: classes.navigation,
        search: classes.search,
        infoButton: classes.infoButton,
        infoIcon: classes.infoIcon,
        mobileToolbar: classes.mobileToolbar,
        mobileMenu: classes.mobileMenu,
        drawer: classes.drawer,
        mobileRightMenu: classes.mobileRightMenu,
        avatarMobile: classes.avatarMobile,
        searchBar: classes.searchBar,
        inputMobile: classes.inputMobile,
        inputs: classes.inputs,
        category: classes.category,
        popper: classes.popper
      };
    }

    return (
      <div className={classes.root}>
        <Bar
          avatar={avatar}
          categories={
            mobile ? this.props.hashedCategories : this.props.categories
          }
          classes={classesForBar}
          user={this.props.userInfo}
          brands={this.props.brands}
          privateStats={this.props.privateStats}
          isLoggedIn={this.props.isLoggedIn}
          title={this.props.title}
          subtitle={this.props.subtitle}
          search={this.state.search}
          handleSearchChange={this.handleSearchChange}
          searchMode={this.state.searchMode}
          setSearchMode={this.setSearchMode}
          focusSearch={this.focusSearch}
          goSearch={this.goSearch}
          searchFocused={this.state.searchFocused}
          mobileSearchable={mobileSearchable}
          noBar={this.props.noBar}
          noInfo={this.props.noInfo}
          noProfile={this.props.noProfile}
        />
        <div className={classes.appContent}>
          {mobile && this.state.searchFocused && (
            <div className={classes.search}>
              <div className={classes.searchButtons}>
                <Button
                  onClick={() => {
                    this.setSearchMode('products');
                  }}
                  className={
                    this.state.searchMode === 'products'
                      ? classes.activeButton
                      : ''
                  }
                >
                  En productos
                </Button>
                <Button
                  onClick={() => {
                    this.setSearchMode('dressers');
                  }}
                  className={
                    this.state.searchMode === 'dressers'
                      ? classes.activeButton
                      : ''
                  }
                >
                  En vestidores
                </Button>
              </div>
              <Divider
                style={{ alignSelf: 'auto', width: '100%', margin: '8px' }}
              />
            </div>
          )}
          {cartPreviewOpen && <CartPreview />}
          {this.props.notFound && (
            <div className={classes.notFound}>
              <Typography
                style={{ fontWeight: 800 }}
                color="primary"
                variant="h4"
              >
                <SvgIcon
                  fontSize="large"
                  style={{ marginRight: '8px' }}
                  viewBox="0 0 71 60"
                >
                  <HeartBroken />
                </SvgIcon>
                Ooops
              </Typography>
              <Typography variant="h6">
                Eso que buscabas no está disponible
              </Typography>
              <Typography
                style={{ fontWeight: 500 }}
                color="primary"
                variant="h6"
              >
                Seguí descubriendo más acá:
              </Typography>
            </div>
          )}
          {!this.props.notFound && (
            <Message
              publishedProduct={this.props.publishedProduct}
              welcomeBuyerCampaign={this.props.welcomeBuyerCampaign}
              welcomeSellerCampaign={this.props.welcomeSellerCampaign}
              message={this.state.message}
              classes={classes}
            />
          )}
          {!this.state.message &&
            !this.props.notFound &&
            this.props.showBanners && (
              <>
                <Banners />
                {mobile && (
                  <div className={classes.sustainabilityContainer}>
                    <SustainabilityMeter />
                  </div>
                )}
              </>
            )}
          {this.props.children}
          {this.props.footer && (
            <>
              <Footer
                noMargin={this.props.noFooterMargin}
                onlyLegalFooter={this.props.onlyLegalFooter}
              />
              {mobile && !cartPreviewOpen && (
                <DownloadAppBanner isLoggedIn={this.props.isLoggedIn} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  cartModalStatus: state.user.status,
  userInfo: state.user.user,
  shoppingCartPreview: state.user.shoppingCartPreview,
  privateStats: state.user.privateStats,
  isLoggedIn: state.auth.isLoggedIn,
  categories: state.categories.categories,
  hashedCategories: state.categories.hashedCategories,
  brands: state.categories.brands
});

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  logout: () => dispatch(logout()),
  validateShowroom: () => dispatch({ type: 'VALIDATE_SHOWROOM' }),
  fetchBrandsAndCategories: () => {
    batch(() => {
      dispatch(fetchCategories());
      dispatch(fetchBrands());
    });
  }
});

export default withRouter(
  withStyles(styles)(
    withWidth()(connect(mapStateToProps, mapDispatchToProps)(AppLayout))
  )
);
