import React, { Component } from 'react';
import lodash from 'lodash';
import { Link as NavLink } from 'react-router-dom';
import { Grid, Typography, Paper, Divider } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import RTVAvatar from './RTVAvatar';
import RoleIcon from './RoleIcon';
import AdornedButton from './AdornedButton';
import { getUserProducts } from '../redux/actions/product';
import {
  fetchPublicStats,
  fetchUser,
  fetchReviews
} from '../redux/actions/user';
import {
  USER_RECEIVED_REVIEWS_REQUESTED,
  USER_RECEIVED_REVIEWS_RETRIEVED,
  USER_RECEIVED_REVIEWS_FAILED,
  USER_RETRIEVED
} from '../redux/actions/types';
import LoadingGif from '../images/Loading.gif';
import RTVRating from './RTVRating';
import Slider from './Slider';
import RTVReview from './RTVReview';
import RTVFollowButton from './RTVFollowButton';
import ComingSoonImage from '../images/Carteles/ComingSoon.svg';
import ProductImageFail from '../images/ProductImageFail.png';
import { getPromotionImage } from '../utils/discountImage';
import abbreviateNumbers from '../utils/abbreviateNumbers';

const styles = theme => ({
  root: {
    minWidth: '350px',
    width: '350px',
    margin: theme.spacing(1),
    borderRadius: '8px',
    height: '261px',
    '@media (max-width: 350px)': {
      minWidth: 'unset'
    }
  },
  header: ({ extended, user: { roles, type } }) => {
    const GRADIENTS = {
      ROLE_PREMIUM: [
        theme.palette.gradients.premium[0],
        theme.palette.gradients.premium[1]
      ],
      ROLE_SHOWROOM: [
        theme.palette.gradients.showroom[0],
        theme.palette.gradients.showroom[1]
      ],
      ROLE_CELEBRITY: [
        theme.palette.gradients.celebrity[0],
        theme.palette.gradients.celebrity[1]
      ],
      ROLE_VIP: [
        theme.palette.gradients.vip[0],
        theme.palette.gradients.vip[1]
      ],
      DEFAULT: [
        theme.palette.gradients.regular[0],
        theme.palette.gradients.regular[1]
      ]
    };

    const role = type || roles.find(r => GRADIENTS[r]);
    const gradient = GRADIENTS[role] || GRADIENTS.DEFAULT;
    const background = `linear-gradient(90deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`;

    if (extended) {
      return {
        background,
        height: '100px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '8px 8px 0 0'
      };
    }

    return {
      background,
      height: '65px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderRadius: '8px 8px 0 0'
    };
  },
  paper: ({ fullWidth }) => ({
    borderRadius: '8px',
    '@media (max-width: 350px)': {
      maxWidth: fullWidth ? 'unset' : '245px'
    }
  }),
  productsSmallContainer: {
    '& > *': {
      height: '125px',
      maxWidth: '110px'
    }
  },
  productsSection: {
    padding: theme.spacing(2, 0.5, 2, 0.5),
    marginTop: theme.spacing(2),
    height: '180px',
    maxHeight: '180px'
  },
  productList: {
    height: '125px',
    width: '300px',
    padding: theme.spacing(2),
    overflow: 'hidden'
  },
  avatarSection: {
    position: 'relative'
  },
  roleIcon: {
    position: 'absolute',
    zIndex: 1,
    width: '50px',
    height: '50px',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  followButton: ({ extended }) => {
    if (extended) {
      return {
        color: '#fdfbfc',
        margin: theme.spacing(2),
        width: '100px',
        borderRadius: '10px',
        padding: theme.spacing(0, 0.5, 0, 0.5)
      };
    }

    return {
      width: '100px',
      position: 'absolute',
      borderRadius: '10px',
      bottom: '-10px',
      padding: theme.spacing(0, 0.5, 0, 0.5),
      marginBottom: '0',
      '& > *': {
        color: '#fdfbfc',
        fontWeight: 500,
        padding: 0
      }
    };
  },
  avatar: ({ extended }) => {
    if (extended) {
      return {
        height: '100px',
        width: '100px',
        margin: theme.spacing(2),
        marginBottom: '-15px'
      };
    }

    return {
      height: '75px',
      width: '75px',
      margin: theme.spacing(1)
    };
  },
  username: {
    fontWeight: 800,
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#000'
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleInfo: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start'
  },
  dresserLink: {
    color: '#fff',
    fontWeight: 800,
    cursor: 'pointer',
    fontSize: '1rem',
    textShadow: '0px 0px 10px rgba(0,0,0,0.5)'
  },
  navLink: {
    height: '100%'
  },
  infoSection: ({ extended }) => {
    if (extended) {
      return {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: theme.spacing(2),
        minHeight: '100px',
        '& hr': {
          alignSelf: 'stretch',
          height: 'auto'
        }
      };
    }
    return {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      flex: 1,
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      justifyContent: 'space-evenly'
    };
  },
  infoSectionData: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  productImage: ({ extended }) => ({
    margin: theme.spacing(1),
    cursor: 'pointer',
    height: extended ? '175px' : '100%'
  }),
  products: {
    maxWidth: '600px'
  },
  reviewsTitle: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  reviewTitle: {
    fontWeight: 800,
    marginRight: theme.spacing(2)
  },
  viewMore: {
    display: 'flex',
    alignItems: 'center',
    color: '#707070',
    textDecoration: 'none'
  },
  followers: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 1)
    }
  },
  reviewsSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  wrapper: ({ noWrapperHeight }) => ({
    height: noWrapperHeight ? 'unset' : '100%'
  }),
  promotionImage: {
    width: '50px',
    height: '50px',
    position: 'absolute',
    right: '8px',
    top: '25px',
    [theme.breakpoints.down('xs')]: {
      width: '30px',
      height: '30px',
      top: '30px',
      right: '4px'
    }
  },
  extendedRoot: {
    width: '560px'
  }
});

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      followers: props.user.followers_total,
      following: false,
      products: props.user.products || [],
      publicStats: props.publicStats[this.props.user.id] || {},
      reviews: props.reviews ? props.reviews[props.user.id] || [] : [],

      loadingReviews: false
    };
  }

  componentDidMount() {
    if (!this.props.noProductsFetch) {
      this.props.getProducts(this.props.user.id);
    }
    if (this.props.extended) {
      this.props.fetchUser(this.props.user.id);
      this.props.fetchReviews(this.props.user.id);
      this.props.fetchPublicStats(this.props.user.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (!lodash.isEqual(prevProps.user, this.props.user)) {
      if (!this.props.noProductsFetch) {
        this.props.getProducts(this.props.user.id);
      }
      if (this.props.extended) {
        this.props.fetchUser(this.props.user.id);
        this.props.fetchReviews(this.props.user.id);
        this.props.fetchPublicStats(this.props.user.id);
      }
    }

    if (
      !this.props.noProductsFetch &&
      !lodash.isEqual(
        prevProps.productsFromUser.products,
        this.props.productsFromUser.products
      )
    ) {
      if (this.props.productsFromUser.user !== this.props.user.id) {
        return;
      }

      this.setState({
        products: this.props.productsFromUser.products
      });
    }

    if (!lodash.isEqual(prevProps.publicStats, this.props.publicStats)) {
      if (this.props.publicStats.requesting !== this.props.user.id) {
        return;
      }

      this.setState({
        publicStats: this.props.publicStats[this.props.user.id] || {}
      });
    }

    if (prevProps.usersStatus !== this.props.usersStatus) {
      if (this.props.usersStatus === USER_RECEIVED_REVIEWS_REQUESTED) {
        this.setState({ loadingReviews: true });
      }
      if (this.props.usersStatus === USER_RECEIVED_REVIEWS_RETRIEVED) {
        this.setState({ loadingReviews: false });
      }
      if (this.props.usersStatus === USER_RECEIVED_REVIEWS_FAILED) {
        this.setState({ loadingReviews: false });
      }

      if (this.props.usersStatus === USER_RETRIEVED) {
        this.setState(state => ({
          ...state,
          user: {
            ...state.user,
            ...this.props.users[this.props.user.id]
          }
        }));
      }
    }

    if (!lodash.isEqual(prevProps.reviews, this.props.reviews)) {
      if (this.props.reviews.requesting !== this.props.user.id) {
        return;
      }

      if (this.props.reviews[this.state.user.id]) {
        this.setState(state => ({
          ...state,
          reviews: this.props.reviews[state.user.id]
        }));
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { user, products, publicStats } = this.state;

    let avatarSrc = null;
    if (user.media) {
      avatarSrc = user.media.default_small || user.media.user_small;
    }

    const croppedUsername =
      user.username.slice(0, 20) + (user.username.length > 20 ? '…' : '');

    if (this.props.extended) {
      return (
        <div className={classes.extendedRoot}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.headerTitle}>
                <div className={classes.avatarSection}>
                  <RoleIcon className={classes.roleIcon} user={user} />
                  <RTVAvatar
                    className={classes.avatar}
                    src={avatarSrc}
                    component={NavLink}
                    to={`/vestidor/${user.username}`}
                  />
                </div>
                <div className={classes.titleInfo}>
                  <Typography
                    component={NavLink}
                    to={`/vestidor/${user.username}`}
                    className={classes.username}
                  >
                    {croppedUsername}
                  </Typography>
                  <RTVRating
                    name="profile-rating"
                    value={user.average_review_stars}
                    readOnly
                  />
                </div>
              </div>
              <div
                style={{ alignItems: 'center' }}
                className={classes.titleInfo}
              >
                <RTVFollowButton user={user.id}>
                  {followProps => (
                    <AdornedButton
                      className={classes.followButton}
                      variant="contained"
                      disabled={followProps.loading}
                      onClick={followProps.onClick}
                      loading={followProps.loading}
                      color={followProps.following ? 'secondary' : 'primary'}
                    >
                      {followProps.following ? 'Siguiendo' : 'Seguir'}
                    </AdornedButton>
                  )}
                </RTVFollowButton>
                <Typography
                  component={NavLink}
                  className={classes.dresserLink}
                  to={`/vestidor/${user.username}/productos`}
                >
                  Ir al vestidor
                </Typography>
              </div>
            </div>
            <div className={classes.infoSection}>
              <div className={classes.infoSectionData}>
                <Typography
                  className={classes.username}
                  component={NavLink}
                  to={`/vestidor/${user.username}/sobre-mi`}
                >
                  {publicStats.total_sales !== undefined
                    ? publicStats.total_sales
                    : '-'}
                </Typography>
                <Typography
                  component={NavLink}
                  to={`/vestidor/${user.username}/sobre-mi`}
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  Ventas
                </Typography>
              </div>
              <Divider orientation="vertical" />
              <div className={classes.infoSectionData}>
                <Typography
                  className={classes.username}
                  component={NavLink}
                  to={`/vestidor/${user.username}/productos`}
                >
                  {publicStats.total_products !== undefined
                    ? publicStats.total_products
                    : '-'}
                </Typography>
                <Typography
                  component={NavLink}
                  to={`/vestidor/${user.username}/productos`}
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  Productos
                </Typography>
              </div>
              <Divider orientation="vertical" />
              <div className={classes.infoSectionData}>
                <Typography
                  className={classes.username}
                  component={NavLink}
                  to={`/vestidor/${user.username}/seguidoras`}
                >
                  <RTVFollowButton user={user.id}>
                    {followProps => (
                      <>
                        {user.followers_total === undefined && '-'}
                        {followProps.justActed &&
                          followProps.following &&
                          abbreviateNumbers(user.followers_total + 1)}
                        {followProps.justActed &&
                          !followProps.following &&
                          abbreviateNumbers(user.followers_total - 1)}
                        {!followProps.justActed &&
                          abbreviateNumbers(user.followers_total)}
                      </>
                    )}
                  </RTVFollowButton>
                </Typography>
                <Typography
                  component={NavLink}
                  to={`/vestidor/${user.username}/seguidoras`}
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  Seguidoras
                </Typography>
              </div>
            </div>
            <div className={classes.products}>
              <Slider classes={{ wrapper: classes.wrapper }}>
                {products.map(product => (
                  <NavLink
                    className={classes.navLink}
                    key={product.id}
                    to={`/producto/id/${product.id}`}
                  >
                    <img
                      className={classes.productImage}
                      src={
                        product.main_image
                          ? product.main_image.default_small ||
                            product.main_image.product_small
                          : ProductImageFail
                      }
                      alt={`Product ${product.title}`}
                      style={{
                        height: `${
                          lodash.isEmpty(product.main_image) && '100px'
                        }`
                      }}
                    />
                  </NavLink>
                ))}
              </Slider>
            </div>
            <div className={classes.comments}>
              {this.state.reviews.length > 0 && (
                <>
                  <div className={classes.reviewsTitle}>
                    <Typography className={classes.reviewTitle}>
                      Últimas calificaciones
                    </Typography>
                    <Typography
                      className={classes.viewMore}
                      component={NavLink}
                      to={`/vestidor/${user.username}/sobre-mi`}
                    >
                      Ver todas
                      <ChevronRight color="primary" />
                    </Typography>
                  </div>
                  <div className={classes.reviewsSection}>
                    {this.state.reviews.slice(0, 2).map(review => (
                      <RTVReview key={review.id} review={review} />
                    ))}
                  </div>
                </>
              )}
              {this.state.loadingReviews && (
                <img src={LoadingGif} alt="Loading" />
              )}
            </div>
          </Paper>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container className={classes.header}>
            <div className={classes.avatarSection}>
              <RTVAvatar
                className={classes.avatar}
                alt={user.username}
                src={avatarSrc}
                component={NavLink}
                to={
                  this.props.registeringProcess
                    ? '#'
                    : `/vestidor/${user.username}`
                }
              />
            </div>
            <div className={classes.infoSection}>
              <Typography
                style={{
                  fontWeight: '800',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  color: '#414042'
                }}
                component={NavLink}
                to={
                  this.props.registeringProcess
                    ? '#'
                    : `/vestidor/${user.username}`
                }
              >
                {croppedUsername}
              </Typography>
              <Typography
                className={classes.followers}
                style={{
                  fontSize: '0.9rem',
                  textDecoration: 'none',
                  color: '#414042'
                }}
                component={NavLink}
                to={
                  this.props.registeringProcess
                    ? '#'
                    : `/vestidor/${user.username}/seguidoras`
                }
              >
                <RTVFollowButton user={user.id}>
                  {followProps => (
                    <>
                      {user.followers_total === undefined && '-'}
                      {followProps.justActed &&
                        followProps.following &&
                        `${
                          user.followers_total
                            ? abbreviateNumbers(user.followers_total + 1)
                            : ''
                        } Seguidoras`}
                      {followProps.justActed &&
                        !followProps.following &&
                        `${
                          user.followers_total
                            ? abbreviateNumbers(user.followers_total - 1)
                            : ''
                        } Seguidoras`}
                      {!followProps.justActed &&
                        `${
                          user.followers_total
                            ? abbreviateNumbers(user.followers_total)
                            : ''
                        } Seguidoras `}
                    </>
                  )}
                </RTVFollowButton>
                <RoleIcon user={user} />
              </Typography>
              <RTVFollowButton user={user.id}>
                {followProps => (
                  <AdornedButton
                    className={classes.followButton}
                    variant="contained"
                    disabled={followProps.loading}
                    onClick={followProps.onClick}
                    loading={followProps.loading}
                    color={followProps.following ? 'secondary' : 'primary'}
                  >
                    {followProps.following ? 'Siguiendo' : 'Seguir'}
                  </AdornedButton>
                )}
              </RTVFollowButton>
              {user.promotion !== null && (
                <img
                  className={classes.promotionImage}
                  src={getPromotionImage(user.promotion)}
                  alt="promocion vestidor"
                />
              )}
            </div>
          </Grid>
          <div className={classes.productsSection}>
            {this.state.products.length === 0 && (
              <img src={ComingSoonImage} alt="coming soon!" />
            )}
            <Slider
              classes={{
                wrapper: classes.wrapper,
                itemContainer: classes.productsSmallContainer
              }}
            >
              {products.map(product => (
                <NavLink
                  key={product.id}
                  to={
                    this.props.registeringProcess
                      ? '#'
                      : `/producto/id/${product.id}`
                  }
                >
                  <img
                    className={classes.productImage}
                    src={
                      product.main_image &&
                      (product.main_image.default_small ||
                        product.main_image.product_small)
                    }
                    alt={`Product ${product.title}`}
                  />
                </NavLink>
              ))}
            </Slider>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  publicStats: state.user.publicStats,
  users: state.user.users,
  reviews: state.user.reviews,
  usersStatus: state.user.status,

  productsFromUser: state.product.productsFromUser,

  privateStats: state.user.privateStats
});

const mapDispatchToProps = dispatch => ({
  getProducts: user => dispatch(getUserProducts(user)),
  fetchPublicStats: user => dispatch(fetchPublicStats(user)),
  fetchUser: user => dispatch(fetchUser(user)),
  fetchReviews: user => dispatch(fetchReviews(user, 1, 2))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UserCard)
);
