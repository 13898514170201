import {
  REGISTER_SUCCESSFULL,
  CHANGE_EMAIL_REQUESTED,
  CHANGE_EMAIL_SUCCESSFULL,
  CHANGE_EMAIL_ERRORED,
  PIN_VERIFY_REQUESTED,
  PIN_VERIFY_SUCCESSFULL,
  PIN_VERIFY_FAILED,
  USERS_RECOMMENDS_REQUESTED,
  USERS_RECOMMENDS_RETRIEVED,
  USERS_RECOMMENDS_FAILED,
  USER_PRIVATE_STATS_REQUESTED,
  USER_PRIVATE_STATS_RETRIEVED,
  USER_PRIVATE_STATS_FAILED,
  USER_PUBLIC_STATS_REQUESTED,
  USER_PUBLIC_STATS_RETRIEVED,
  USER_PUBLIC_STATS_FAILED,
  USER_SELF_REQUESTED,
  USER_SELF_RETRIEVED,
  USER_SELF_FAILED,
  USER_RECEIVED_REVIEWS_REQUESTED,
  USER_RECEIVED_REVIEWS_RETRIEVED,
  USER_RECEIVED_REVIEWS_FAILED,
  USER_REQUESTED,
  USER_RETRIEVED,
  USER_FAILED,
  UPDATE_USER_PASSWORD_REQUESTED,
  UPDATE_USER_PASSWORD_SUCCESSFULL,
  UPDATE_USER_PASSWORD_ERRORED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_SUCCESSFULL,
  UPDATE_USER_ERRORED,
  FAVOURITE_LIST_REQUESTED,
  FAVOURITE_LIST_RETRIEVED,
  FAVOURITE_LIST_FAILED,
  FOLLOW_LIST_REQUESTED,
  FOLLOW_LIST_RETRIEVED,
  FOLLOW_LIST_FAILED,
  LIKE_PRODUCT_SUCCESSFULL,
  LIKE_PRODUCT_FAILED,
  UNLIKE_PRODUCT_SUCCESSFULL,
  UNLIKE_PRODUCT_FAILED,
  FOLLOW_SUCCEED,
  FOLLOW_FAILED,
  UNFOLLOW_SUCCEED,
  UNFOLLOW_FAILED,
  SHOPPING_CART_ADD_REQUESTED,
  SHOPPING_CART_ADD_SUCCESSFULL,
  SHOPPING_CART_ADD_ERRORED,
  SHOPPING_CART_REMOVE_REQUESTED,
  SHOPPING_CART_REMOVE_SUCCESSFULL,
  SHOPPING_CART_REMOVE_ERRORED,
  SHOPPING_CART_REQUESTED,
  SHOPPING_CART_SUCCESSFULL,
  SHOPPING_CART_ERRORED,
  SHIPPING_CARRIERS_REQUESTED,
  SHIPPING_CARRIERS_RETRIEVED,
  SHIPPING_CARRIERS_FAILED,
  SHIPPING_ADDRESSES_REQUESTED,
  SHIPPING_ADDRESSES_RETRIEVED,
  SHIPPING_ADDRESSES_FAILED,
  SHIPPING_ADDRESS_CREATE_REQUESTED,
  SHIPPING_ADDRESS_CREATE_SUCCESSFULL,
  SHIPPING_ADDRESS_CREATE_FAILED,
  SHIPPING_STORE_CREATE_REQUESTED,
  SHIPPING_STORE_CREATE_SUCCESSFULL,
  SHIPPING_STORE_CREATE_FAILED,
  SHIPPING_ADDRESS_DELETE_SUCCESSFULL,
  CREATE_WITHDRAWAL_SUCCESSFULL,
  FOLLOWINGS_RETRIEVED,
  FOLLOWERS_RETRIEVED,
  NOTIFICATION_READ_SUCCESFULL,
  DRESSERS_FAILED,
  DRESSERS_RETRIEVED,
  DRESSERS_REQUESTED,
  ACTIVATE_HOLIDAY_MODE_REQUESTED,
  ACTIVATE_HOLIDAY_MODE_SUCCESSFULL,
  ACTIVATE_HOLIDAY_MODE_ERRORED,
  DEACTIVATE_HOLIDAY_MODE_REQUESTED,
  DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL,
  DEACTIVATE_HOLIDAY_MODE_ERRORED,
  ENABLE_USER_REQUESTED,
  ENABLE_USER_SUCCESSFULL,
  ENABLE_USER_ERRORED,
  DISABLE_USER_REQUESTED,
  DISABLE_USER_ERRORED,
  CREATE_PROMOTION_REQUESTED,
  CREATE_PROMOTION_SUCCESSFULL,
  CREATE_PROMOTION_ERRORED,
  DELETE_PROMOTION_REQUESTED,
  DELETE_PROMOTION_SUCCESSFULL,
  DELETE_PROMOTION_ERRORED,
  ANSWER_REVIEW_REQUESTED,
  ANSWER_REVIEW_SUCCESSFULL,
  ANSWER_REVIEW_ERRORED,
  CALCULATE_DISCOUNT_REQUESTED,
  CALCULATE_DISCOUNT_SUCCESSFULL,
  CALCULATE_DISCOUNT_ERRORED,
  CALCULATE_PROMOTION_REQUESTED,
  CALCULATE_PROMOTION_SUCCESSFULL,
  CALCULATE_PROMOTION_ERRORED,
  CREATE_REVIEW_REQUESTED,
  CREATE_REVIEW_SUCCESSFULL,
  CREATE_REVIEW_ERRORED,
  CAMPAIGNS_RETRIEVED,
  CAMPAIGNS_FAILED,
  CAMPAIGNS_REQUESTED,
  WELCOME_BUYER_CAMPAIGN_RETRIEVED,
  WELCOME_BUYER_CAMPAIGN_FAILED,
  WELCOME_BUYER_CAMPAIGN_REQUESTED,
  WELCOME_SELLER_CAMPAIGN_RETRIEVED,
  WELCOME_SELLER_CAMPAIGN_FAILED,
  WELCOME_SELLER_CAMPAIGN_REQUESTED,
  FREE_SHIPPING_CAMPAIGN_RETRIEVED,
  FREE_SHIPPING_CAMPAIGN_FAILED,
  FREE_SHIPPING_CAMPAIGN_REQUESTED,
  UTM_STORE,
  UTM_REMOVE,
  POST_UTM_REQUESTED,
  POST_UTM_SUCCESSFULL,
  POST_UTM_ERRORED,
  BUYER_OFFERS_REQUESTED,
  BUYER_OFFERS_RETRIEVED,
  BUYER_OFFERS_ERRORED,
  LOGOUT_REQUESTED,
  HOLIDAY_CHECK_PERFORMED,
  USER_COUPONS_REQUESTED,
  USER_COUPONS_SUCCESSFULL,
  USER_COUPONS_ERRORED,
  USER_ALERTS_REQUESTED,
  USER_ALERTS_SUCCESSFULL,
  USER_ALERTS_ERRORED,
  CREATE_ALERT_REQUESTED,
  CREATE_ALERT_SUCCESSFULL,
  CREATE_ALERT_ERRORED,
  EDIT_ALERT_ERRORED,
  EDIT_ALERT_SUCCESSFULL,
  EDIT_ALERT_REQUESTED,
  DELETE_ALERT_REQUESTED,
  DELETE_ALERT_SUCCESSFULL,
  DELETE_ALERT_ERRORED,
  UPDATE_PERSON_ID_REQUESTED,
  UPDATE_PERSON_ID_SUCCESSFULL,
  UPDATE_PERSON_ID_ERRORED,
  GET_USER_AFIP_INFO_REQUESTED,
  GET_USER_AFIP_INFO_SUCCESSFULL,
  GET_USER_AFIP_INFO_ERRORED,
  CREATE_USER_PROFILES_REQUESTED,
  CREATE_USER_PROFILES_SUCCESSFUL,
  CREATE_USER_PROFILES_ERRORED,
  GET_USER_PROFILES_REQUESTED,
  GET_USER_PROFILES_SUCCESSFUL,
  GET_USER_PROFILES_ERRORED,
  CREATE_PAYMENT_METHOD_REQUESTED,
  CREATE_PAYMENT_METHOD_SUCCESSFUL,
  CREATE_PAYMENT_METHOD_ERRORED,
  VALIDATE_ACCOUNT_REQUESTED,
  VALIDATE_ACCOUNT_SUCCESSFUL,
  VALIDATE_ACCOUNT_ERRORED,
  GET_PAYMENT_METHODS_REQUESTED,
  GET_PAYMENT_METHODS_SUCCESSFUL,
  GET_PAYMENT_METHODS_ERRORED,
  OPEN_SHOPPING_CART_PREVIEW,
  CLOSE_SHOPPING_CART_PREVIEW,
  CLEAN_SHOPPING_CART_REQUESTED,
  CLEAN_SHOPPING_CART_SUCCESSFULL,
  CLEAN_SHOPPING_CART_ERRORED
} from '../actions/types';

const defaultState = {
  user: null,
  users: {
    requesting: ''
  },
  reviews: {
    requesting: ''
  },
  privateStats: {},
  publicStats: {
    requesting: ''
  },
  shippingAddresses: [],
  mostRecentAddress: {},
  localStats: {
    products_favourite_last_updated: new Date().getTime(),
    users_following_last_updated: new Date().getTime(),
    first_followings_request: false,
    first_favourites_request: false,
    followings: [],
    favourites: [],
    utm: null,
    lastHolidayCheck: null
  },
  recommends: [],
  shoppingCart: [],
  shoppingCartPreview: [],
  status: null,
  sellerShippingCarriers: [],
  dressers: [],
  dressersFacets: {},
  discount: null,
  campaigns: [],
  welcomeBuyerCampaign: {},
  welcomeSellerCampaign: {},
  freeShippingCampaigns: [],
  offers: [],
  productAdded: null,
  productAdding: null,
  productRemoved: null,
  productRemoving: null,
  productError: null,
  coupons: [],
  alerts: [],
  alert: null,
  afipInfo: [],
  error: {},
  userProfiles: [],
  paymentMethods: {}
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOGOUT_REQUESTED: {
      return defaultState;
    }
    // LocalStats user reducer related actions.
    case UTM_STORE: {
      return {
        ...state,
        status: UTM_STORE,
        localStats: {
          ...state.localStats,
          utm: action.utm
        }
      };
    }
    case UTM_REMOVE: {
      return {
        ...state,
        status: UTM_REMOVE,
        localStats: {
          ...state.localStats,
          utm: null
        }
      };
    }
    case POST_UTM_REQUESTED: {
      return {
        ...state,
        status: POST_UTM_REQUESTED
      };
    }
    case POST_UTM_SUCCESSFULL: {
      return {
        ...state,
        status: POST_UTM_SUCCESSFULL,
        localStats: {
          ...state.localStats,
          utm: null
        }
      };
    }
    case POST_UTM_ERRORED: {
      return {
        ...state,
        status: POST_UTM_ERRORED
      };
    }
    case FOLLOW_LIST_REQUESTED: {
      return {
        ...state,
        status: FOLLOW_LIST_REQUESTED
      };
    }
    case FOLLOW_LIST_RETRIEVED: {
      return {
        ...state,
        status: FOLLOW_LIST_RETRIEVED,
        localStats: {
          ...state.localStats,
          users_following_last_updated: new Date().getTime(),
          first_followings_request: true,
          followings: action.followings.map(f => f.id)
        }
      };
    }
    case FOLLOW_LIST_FAILED: {
      return {
        ...state,
        status: FOLLOW_LIST_FAILED,
        error: action.error
      };
    }
    case FAVOURITE_LIST_REQUESTED: {
      return {
        ...state,
        status: FAVOURITE_LIST_REQUESTED
      };
    }
    case FAVOURITE_LIST_RETRIEVED: {
      return {
        ...state,
        status: FAVOURITE_LIST_RETRIEVED,
        localStats: {
          ...state.localStats,
          favourites: action.favourites.map(p => p.id),
          first_favourites_request: true,
          products_favourite_last_updated: new Date().getTime()
        }
      };
    }
    case FAVOURITE_LIST_FAILED: {
      return {
        ...state,
        status: FAVOURITE_LIST_FAILED,
        error: action.error
      };
    }
    case LIKE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: LIKE_PRODUCT_SUCCESSFULL,
        localStats: {
          ...state.localStats,
          products_favourite_last_updated: new Date().getTime(),
          favourites: [...state.localStats.favourites, action.product]
        }
      };
    }
    case LIKE_PRODUCT_FAILED: {
      return {
        ...state,
        status: LIKE_PRODUCT_FAILED,
        error: action.error,
        localStats: {
          ...state.localStats,
          favourites:
            action.error.status === 409
              ? // We didn't had the product on favourites for some reason
                // so we need to push it to the favourites localStats array
                [...state.localStats.favourites, action.product]
              : // Some other error, nothing to do here.
                state.localStats.favourites
        }
      };
    }
    case UNLIKE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: UNLIKE_PRODUCT_SUCCESSFULL,
        localStats: {
          ...state.localStats,
          products_favourite_last_updated: new Date().getTime(),
          favourites: state.localStats.favourites.filter(
            product => product !== action.product
          )
        }
      };
    }
    case UNLIKE_PRODUCT_FAILED: {
      return {
        ...state,
        status: UNLIKE_PRODUCT_FAILED,
        error: action.error,
        localStats: {
          ...state.localStats,
          favourites:
            action.error.status === 409
              ? // The product wasn't liked before but seems like we had it
                // on the favourites array, so just erase it from there
                state.localStats.favourites.filter(
                  product => product !== action.product
                )
              : // Some other problem and we should not do anything here
                state.localStats.favourites
        }
      };
    }
    case FOLLOW_SUCCEED: {
      return {
        ...state,
        status: FOLLOW_SUCCEED,
        localStats: {
          ...state.localStats,
          users_following_last_updated: new Date().getTime(),
          followings: [...state.localStats.followings, action.user]
        }
      };
    }
    case FOLLOW_FAILED: {
      return {
        ...state,
        status: FOLLOW_FAILED,
        error: action.error,
        localStats: {
          ...state.localStats,
          followings:
            action.error.status === 409
              ? // We didn't had the user on followings for some reason
                // so we need to push it to the followings localStats array
                [...state.localStats.followings, action.user]
              : // Some other error, nothing to do here.
                state.localStats.followings
        }
      };
    }
    case UNFOLLOW_SUCCEED: {
      return {
        ...state,
        status: UNFOLLOW_SUCCEED,
        localStats: {
          ...state.localStats,
          users_following_last_updated: new Date().getTime(),
          followings: state.localStats.followings.filter(
            user => user !== action.user
          )
        }
      };
    }
    case UNFOLLOW_FAILED: {
      return {
        ...state,
        status: UNFOLLOW_FAILED,
        error: action.error,
        localStats: {
          ...state.localStats,
          followings:
            action.error.status === 409
              ? // The user wasn't followed before but seems like we had it
                // on the followings array, so just erase it from there
                state.localStats.followings.filter(user => user !== action.user)
              : // Some other problem and we should not do anything here
                state.localStats.followings
        }
      };
    }
    // Other user reducer related things.
    case REGISTER_SUCCESSFULL:
      return {
        ...state,
        status: REGISTER_SUCCESSFULL,
        user: action.user,
        localStats: {
          ...state.localStats,
          utm: null
        }
      };
    case CHANGE_EMAIL_REQUESTED:
      return {
        ...state,
        status: CHANGE_EMAIL_REQUESTED
      };
    case CHANGE_EMAIL_SUCCESSFULL:
      return {
        ...state,
        status: CHANGE_EMAIL_SUCCESSFULL,
        user: {
          ...state.user,
          email: action.email
        }
      };
    case CHANGE_EMAIL_ERRORED:
      return {
        ...state,
        status: CHANGE_EMAIL_ERRORED
      };
    case PIN_VERIFY_REQUESTED:
      return {
        ...state,
        status: PIN_VERIFY_REQUESTED
      };
    case PIN_VERIFY_FAILED:
      return {
        ...state,
        status: PIN_VERIFY_FAILED
      };
    case PIN_VERIFY_SUCCESSFULL:
      return {
        ...state,
        status: PIN_VERIFY_SUCCESSFULL
      };
    case USERS_RECOMMENDS_RETRIEVED:
      return {
        ...state,
        status: USERS_RECOMMENDS_RETRIEVED,
        recommends: action.recommends || []
      };
    case USERS_RECOMMENDS_REQUESTED:
      return {
        ...state,
        status: USERS_RECOMMENDS_REQUESTED,
        recommends: []
      };
    case USERS_RECOMMENDS_FAILED:
      return {
        ...state,
        status: USERS_RECOMMENDS_FAILED,
        error: action.error
      };
    case USER_PRIVATE_STATS_REQUESTED:
      return {
        ...state,
        status: USER_PRIVATE_STATS_REQUESTED
      };
    case USER_PRIVATE_STATS_RETRIEVED:
      return {
        ...state,
        status: USER_PRIVATE_STATS_RETRIEVED,
        privateStats: action.stats
      };
    case USER_PRIVATE_STATS_FAILED:
      return {
        ...state,
        status: USER_PRIVATE_STATS_FAILED
      };
    case USER_PUBLIC_STATS_REQUESTED:
      return {
        ...state,
        status: USER_PUBLIC_STATS_REQUESTED,
        publicStats: {
          ...state.publicStats,
          requesting: action.user
        }
      };
    case USER_PUBLIC_STATS_RETRIEVED:
      return {
        ...state,
        status: USER_PUBLIC_STATS_RETRIEVED,
        publicStats: {
          ...state.publicStats,
          requesting: action.user,
          [action.user]: action.stats
        }
      };
    case USER_PUBLIC_STATS_FAILED:
      return {
        ...state,
        status: USER_PUBLIC_STATS_FAILED,
        publicStats: {
          ...state.publicStats,
          requesting: action.user
        }
      };
    case USER_SELF_REQUESTED: {
      return {
        ...state,
        status: USER_SELF_REQUESTED
      };
    }
    case USER_SELF_RETRIEVED: {
      return {
        ...state,
        status: USER_SELF_RETRIEVED,
        user: {
          ...state.user,
          ...action.user
        }
      };
    }
    case USER_SELF_FAILED: {
      return {
        ...state,
        status: USER_SELF_FAILED
      };
    }
    case USER_RECEIVED_REVIEWS_REQUESTED: {
      return {
        ...state,
        status: USER_RECEIVED_REVIEWS_REQUESTED,
        reviews: {
          ...state.reviews,
          requesting: action.user
        }
      };
    }
    case USER_RECEIVED_REVIEWS_RETRIEVED: {
      return {
        ...state,
        status: USER_RECEIVED_REVIEWS_RETRIEVED,
        reviews: {
          ...state.reviews,
          requesting: action.user,
          [action.user]: action.reviews
        }
      };
    }
    case USER_RECEIVED_REVIEWS_FAILED: {
      return {
        ...state,
        status: USER_RECEIVED_REVIEWS_FAILED,
        reviews: {
          ...state.reviews,
          requesting: action.user
        }
      };
    }
    case USER_REQUESTED: {
      return {
        ...state,
        status: USER_REQUESTED,
        users: {
          ...state.users,
          requesting: action.user,
          [action.idType === 'id' ? action.user : action.user.toLowerCase()]: {}
        }
      };
    }
    case USER_RETRIEVED: {
      return {
        ...state,
        status: USER_RETRIEVED,
        users: {
          ...state.users,
          requesting: action.requestingUser,
          [action.user.id]: action.user,
          // Duplicate field o we can handle the cases
          // where the user is requested with only the
          // username and an unknown ID.
          [action.user.username.toLowerCase()]: action.user
        }
      };
    }
    case USER_FAILED: {
      return {
        ...state,
        status: USER_FAILED,
        error: action.error,
        users: {
          ...state.users,
          requesting: action.user
        }
      };
    }
    case UPDATE_USER_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_USER_SUCCESSFULL,
        users: {
          [action.user.id]: {
            ...state[action.user.id],
            ...action.user
          },
          [action.user.username.toLowerCase()]: {
            ...state[action.user.username.toLowerCase()],
            ...action.user
          }
        }
      };
    }
    case UPDATE_USER_REQUESTED: {
      return {
        ...state,
        status: UPDATE_USER_REQUESTED
      };
    }
    case UPDATE_USER_ERRORED: {
      return {
        ...state,
        status: UPDATE_USER_ERRORED
      };
    }
    case UPDATE_USER_PASSWORD_REQUESTED:
      return {
        ...state,
        status: UPDATE_USER_PASSWORD_REQUESTED
      };
    case UPDATE_USER_PASSWORD_SUCCESSFULL:
      return {
        ...state,
        status: UPDATE_USER_PASSWORD_SUCCESSFULL
      };
    case UPDATE_USER_PASSWORD_ERRORED:
      return {
        ...state,
        status: UPDATE_USER_PASSWORD_ERRORED
      };
    case SHOPPING_CART_ADD_REQUESTED: {
      return {
        ...state,
        status: SHOPPING_CART_ADD_REQUESTED,
        productAdding: action.productAdding
      };
    }
    case SHOPPING_CART_ADD_SUCCESSFULL: {
      // added product goes on top of the list
      const shoppingCart = action.shoppingCart.sort((a, b) => {
        if (a.id === action.productAdded) return -1;
        if (b.id === action.productAdded) return 1;
        return 0;
      });
      return {
        ...state,
        status: SHOPPING_CART_ADD_SUCCESSFULL,
        shoppingCart,
        productAdded: action.productAdded,
        productAdding: null,
        privateStats: {
          ...state.privateStats,
          shopping_cart_count: state.privateStats.shopping_cart_count + 1
        }
      };
    }
    case SHOPPING_CART_ADD_ERRORED: {
      return {
        ...state,
        status: SHOPPING_CART_ADD_ERRORED,
        productError: action.product,
        error: action.error,
        productAdding: null
      };
    }
    case SHOPPING_CART_REMOVE_REQUESTED: {
      return {
        ...state,
        status: SHOPPING_CART_REMOVE_REQUESTED,
        productRemoving: action.productRemoving
      };
    }
    case SHOPPING_CART_REMOVE_SUCCESSFULL: {
      return {
        ...state,
        status: SHOPPING_CART_REMOVE_SUCCESSFULL,
        shoppingCart: action.shoppingCart,
        productRemoved: action.productRemoved,
        productRemoving: null,
        privateStats: {
          ...state.privateStats,
          shopping_cart_count: state.privateStats.shopping_cart_count - 1
        }
      };
    }
    case SHOPPING_CART_REMOVE_ERRORED: {
      return {
        ...state,
        status: SHOPPING_CART_REMOVE_ERRORED,
        productRemoving: null,
        productRemoved: null
      };
    }
    case SHOPPING_CART_REQUESTED: {
      return {
        ...state,
        status: SHOPPING_CART_REQUESTED
      };
    }
    case SHOPPING_CART_SUCCESSFULL: {
      return {
        ...state,
        status: SHOPPING_CART_SUCCESSFULL,
        shoppingCart: action.shoppingCart.map(product => {
          if (product.main_image === null) {
            return { ...product, main_image: {} };
          }
          return product;
        })
      };
    }
    case SHOPPING_CART_ERRORED: {
      return {
        ...state,
        status: SHOPPING_CART_ERRORED
      };
    }
    case SHIPPING_CARRIERS_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_CARRIERS_REQUESTED
      };
    }
    case SHIPPING_CARRIERS_RETRIEVED: {
      return {
        ...state,
        status: SHIPPING_CARRIERS_RETRIEVED,
        sellerShippingCarriers: action.sellerShippingCarriers
      };
    }
    case SHIPPING_CARRIERS_FAILED: {
      return {
        ...state,
        status: SHIPPING_CARRIERS_FAILED,
        error: action.error
      };
    }

    case CLEAN_SHOPPING_CART_REQUESTED: {
      return {
        ...state,
        status: CLEAN_SHOPPING_CART_REQUESTED,
        loading: true
      };
    }
    case CLEAN_SHOPPING_CART_SUCCESSFULL: {
      return {
        ...state,
        status: CLEAN_SHOPPING_CART_SUCCESSFULL,
        shoppingCart: action.shoppingCart,
        loading: false,
        privateStats: {
          ...state.privateStats,
          shopping_cart_count: action.shoppingCart.length
        }
      };
    }
    case CLEAN_SHOPPING_CART_ERRORED: {
      return {
        ...state,
        status: CLEAN_SHOPPING_CART_ERRORED,
        error: action.error,
        loading: false
      };
    }

    case SHIPPING_ADDRESSES_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_ADDRESSES_REQUESTED
      };
    }
    case SHIPPING_ADDRESSES_RETRIEVED: {
      return {
        ...state,
        status: SHIPPING_ADDRESSES_RETRIEVED,
        shippingAddresses: action.shippingAddresses
      };
    }
    case SHIPPING_ADDRESSES_FAILED: {
      return {
        ...state,
        status: SHIPPING_ADDRESSES_FAILED,
        error: action.error
      };
    }
    case SHIPPING_ADDRESS_CREATE_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_ADDRESS_CREATE_REQUESTED
      };
    }
    case SHIPPING_ADDRESS_CREATE_SUCCESSFULL: {
      return {
        ...state,
        status: SHIPPING_ADDRESS_CREATE_SUCCESSFULL,
        shippingAddresses: [...state.shippingAddresses, action.shippingAddress]
      };
    }
    case SHIPPING_ADDRESS_CREATE_FAILED: {
      return {
        ...state,
        status: SHIPPING_ADDRESS_CREATE_FAILED,
        error: action.error
      };
    }
    case SHIPPING_STORE_CREATE_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_STORE_CREATE_REQUESTED
      };
    }
    case SHIPPING_STORE_CREATE_SUCCESSFULL: {
      return {
        ...state,
        status: SHIPPING_STORE_CREATE_SUCCESSFULL,
        shippingAddresses: [...state.shippingAddresses, action.shippingStore],
        mostRecentAddress: action.shippingStore
      };
    }
    case SHIPPING_STORE_CREATE_FAILED: {
      return {
        ...state,
        status: SHIPPING_STORE_CREATE_FAILED
      };
    }
    case SHIPPING_ADDRESS_DELETE_SUCCESSFULL: {
      return {
        ...state,
        status: SHIPPING_ADDRESS_DELETE_SUCCESSFULL,
        shippingAddresses: state.shippingAddresses.filter(
          addr => addr.id !== action.shippingAddress
        )
      };
    }
    case CREATE_WITHDRAWAL_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_WITHDRAWAL_SUCCESSFULL,
        privateStats: {
          ...state.privateStats
        }
      };
    }
    case FOLLOWINGS_RETRIEVED: {
      return {
        ...state,
        user: {
          ...state.user,
          following_total: action.totals.following,
          followers_total: action.totals.followers
        }
      };
    }
    case FOLLOWERS_RETRIEVED: {
      return {
        ...state,
        user: {
          ...state.user,
          following_total: action.totals.following,
          followers_total: action.totals.followers
        }
      };
    }
    case NOTIFICATION_READ_SUCCESFULL: {
      if (!action.clear) {
        return state;
      }
      return {
        ...state,
        status: NOTIFICATION_READ_SUCCESFULL,
        privateStats: {
          ...state.privateStats,
          notifications_count: 0
        }
      };
    }
    case DRESSERS_REQUESTED: {
      return {
        ...state,
        status: DRESSERS_REQUESTED
      };
    }
    case DRESSERS_RETRIEVED: {
      return {
        ...state,
        status: DRESSERS_RETRIEVED,
        dressers: action.dressersResponse.items,
        dressersFacets: action.dressersResponse.facets
      };
    }
    case DRESSERS_FAILED: {
      return {
        ...state,
        status: DRESSERS_FAILED,
        error: action.error
      };
    }
    case ACTIVATE_HOLIDAY_MODE_REQUESTED: {
      return {
        ...state,
        status: ACTIVATE_HOLIDAY_MODE_REQUESTED
      };
    }
    case ACTIVATE_HOLIDAY_MODE_SUCCESSFULL: {
      return {
        ...state,
        status: ACTIVATE_HOLIDAY_MODE_SUCCESSFULL,
        user: {
          ...state.user,
          roles: [...state.user.roles, 'ROLE_VACATION']
        }
      };
    }
    case ACTIVATE_HOLIDAY_MODE_ERRORED: {
      return {
        ...state,
        status: ACTIVATE_HOLIDAY_MODE_ERRORED,
        error: action.error
      };
    }
    case DEACTIVATE_HOLIDAY_MODE_REQUESTED: {
      return {
        ...state,
        status: DEACTIVATE_HOLIDAY_MODE_REQUESTED
      };
    }
    case DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL: {
      return {
        ...state,
        status: DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL,
        user: {
          ...state.user,
          roles: state.user.roles.filter(r => r !== 'ROLE_VACATION')
        }
      };
    }
    case DEACTIVATE_HOLIDAY_MODE_ERRORED: {
      return {
        ...state,
        status: DEACTIVATE_HOLIDAY_MODE_ERRORED,
        error: action.error
      };
    }
    case ENABLE_USER_REQUESTED: {
      return {
        ...state,
        status: ENABLE_USER_REQUESTED
      };
    }
    case ENABLE_USER_SUCCESSFULL: {
      return {
        ...state,
        status: ENABLE_USER_SUCCESSFULL
      };
    }
    case ENABLE_USER_ERRORED: {
      return {
        ...state,
        status: ENABLE_USER_ERRORED
      };
    }
    case DISABLE_USER_REQUESTED: {
      return {
        ...state,
        status: DISABLE_USER_REQUESTED
      };
    }
    case DISABLE_USER_ERRORED: {
      return {
        ...state,
        status: DISABLE_USER_ERRORED
      };
    }
    case CREATE_PROMOTION_REQUESTED: {
      return {
        ...state,
        status: CREATE_PROMOTION_REQUESTED
      };
    }
    case CREATE_PROMOTION_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_PROMOTION_SUCCESSFULL,
        privateStats: {
          ...state.privateStats,
          promotion: action.promotion
        }
      };
    }
    case CREATE_PROMOTION_ERRORED: {
      return {
        ...state,
        status: CREATE_PROMOTION_ERRORED,
        error: action.error
      };
    }
    case DELETE_PROMOTION_REQUESTED: {
      return {
        ...state,
        status: DELETE_PROMOTION_REQUESTED
      };
    }
    case DELETE_PROMOTION_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_PROMOTION_SUCCESSFULL,
        privateStats: {
          ...state.privateStats,
          promotion: null
        }
      };
    }
    case DELETE_PROMOTION_ERRORED: {
      return {
        ...state,
        status: DELETE_PROMOTION_ERRORED,
        error: action.error
      };
    }
    case ANSWER_REVIEW_REQUESTED: {
      return {
        ...state,
        status: ANSWER_REVIEW_REQUESTED
      };
    }
    case ANSWER_REVIEW_SUCCESSFULL: {
      return {
        ...state,
        status: ANSWER_REVIEW_SUCCESSFULL
      };
    }
    case ANSWER_REVIEW_ERRORED: {
      return {
        ...state,
        status: ANSWER_REVIEW_ERRORED
      };
    }
    case CALCULATE_DISCOUNT_REQUESTED: {
      return {
        ...state,
        status: CALCULATE_DISCOUNT_REQUESTED,
        discount: null
      };
    }
    case CALCULATE_DISCOUNT_SUCCESSFULL: {
      return {
        ...state,
        status: CALCULATE_DISCOUNT_SUCCESSFULL,
        discount: action.discount
      };
    }
    case CALCULATE_DISCOUNT_ERRORED: {
      return {
        ...state,
        status: CALCULATE_DISCOUNT_ERRORED,
        error: action.error
      };
    }
    case CALCULATE_PROMOTION_REQUESTED: {
      return {
        ...state,
        status: CALCULATE_PROMOTION_REQUESTED,
        promotionDiscount: null
      };
    }
    case CALCULATE_PROMOTION_SUCCESSFULL: {
      return {
        ...state,
        status: CALCULATE_PROMOTION_SUCCESSFULL,
        promotionDiscount: action.promotionDiscount
      };
    }
    case CALCULATE_PROMOTION_ERRORED: {
      return {
        ...state,
        status: CALCULATE_PROMOTION_ERRORED,
        error: action.error
      };
    }
    case CREATE_REVIEW_REQUESTED: {
      return {
        ...state,
        status: CREATE_REVIEW_REQUESTED
      };
    }
    case CREATE_REVIEW_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_REVIEW_SUCCESSFULL
      };
    }
    case CREATE_REVIEW_ERRORED: {
      return {
        ...state,
        status: CREATE_REVIEW_ERRORED
      };
    }
    case CAMPAIGNS_RETRIEVED: {
      return {
        ...state,
        status: CAMPAIGNS_RETRIEVED,
        campaigns: action.campaigns
      };
    }
    case CAMPAIGNS_FAILED: {
      return {
        ...state,
        status: CAMPAIGNS_FAILED,
        campaigns: []
      };
    }
    case CAMPAIGNS_REQUESTED: {
      return {
        ...state,
        status: CAMPAIGNS_REQUESTED,
        error: action.error
      };
    }
    case WELCOME_BUYER_CAMPAIGN_RETRIEVED: {
      return {
        ...state,
        status: WELCOME_BUYER_CAMPAIGN_RETRIEVED,
        welcomeBuyerCampaign: action.welcomeBuyerCampaign
      };
    }
    case WELCOME_BUYER_CAMPAIGN_FAILED: {
      return {
        ...state,
        status: WELCOME_BUYER_CAMPAIGN_FAILED,
        error: action.error
      };
    }
    case WELCOME_BUYER_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        status: WELCOME_BUYER_CAMPAIGN_REQUESTED
      };
    }
    case WELCOME_SELLER_CAMPAIGN_RETRIEVED: {
      return {
        ...state,
        status: WELCOME_SELLER_CAMPAIGN_RETRIEVED,
        welcomeSellerCampaign: action.welcomeSellerCampaign
      };
    }
    case WELCOME_SELLER_CAMPAIGN_FAILED: {
      return {
        ...state,
        status: WELCOME_SELLER_CAMPAIGN_FAILED,
        error: action.error
      };
    }
    case WELCOME_SELLER_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        status: WELCOME_SELLER_CAMPAIGN_REQUESTED
      };
    }
    case FREE_SHIPPING_CAMPAIGN_RETRIEVED: {
      return {
        ...state,
        status: FREE_SHIPPING_CAMPAIGN_RETRIEVED,
        freeShippingCampaigns: action.freeShippingCampaigns
      };
    }
    case FREE_SHIPPING_CAMPAIGN_FAILED: {
      return {
        ...state,
        status: FREE_SHIPPING_CAMPAIGN_FAILED,
        error: action.error
      };
    }
    case FREE_SHIPPING_CAMPAIGN_REQUESTED: {
      return {
        ...state,
        status: FREE_SHIPPING_CAMPAIGN_REQUESTED
      };
    }
    case BUYER_OFFERS_REQUESTED: {
      return {
        ...state,
        status: BUYER_OFFERS_REQUESTED
      };
    }
    case BUYER_OFFERS_RETRIEVED: {
      return {
        ...state,
        loading: false,
        status: BUYER_OFFERS_RETRIEVED,
        offers: action.buyerOffers
      };
    }
    case BUYER_OFFERS_ERRORED: {
      return {
        ...state,
        loading: false,
        status: BUYER_OFFERS_ERRORED
      };
    }
    case HOLIDAY_CHECK_PERFORMED: {
      return {
        ...state,
        localStats: {
          ...state.localStats,
          lastHolidayCheck: new Date().getTime()
        }
      };
    }
    case USER_COUPONS_REQUESTED: {
      return {
        ...state,
        coupons: [],
        status: USER_COUPONS_REQUESTED
      };
    }
    case USER_COUPONS_SUCCESSFULL: {
      return {
        ...state,
        coupons: action.coupons,
        status: USER_COUPONS_REQUESTED
      };
    }
    case USER_COUPONS_ERRORED: {
      return {
        ...state,
        status: USER_COUPONS_REQUESTED,
        error: action.error
      };
    }
    case USER_ALERTS_REQUESTED: {
      return {
        ...state,
        status: USER_ALERTS_REQUESTED
      };
    }
    case USER_ALERTS_SUCCESSFULL: {
      return {
        ...state,
        status: USER_ALERTS_SUCCESSFULL,
        alerts: action.alerts
      };
    }
    case USER_ALERTS_ERRORED: {
      return {
        ...state,
        status: USER_ALERTS_ERRORED,
        error: action.error
      };
    }
    case CREATE_ALERT_REQUESTED: {
      return {
        ...state,
        status: CREATE_ALERT_REQUESTED
      };
    }
    case CREATE_ALERT_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_ALERT_SUCCESSFULL,
        alerts: [...state.alerts, action.alert]
      };
    }
    case CREATE_ALERT_ERRORED: {
      return {
        ...state,
        status: CREATE_ALERT_ERRORED,
        error: action.error
      };
    }
    case EDIT_ALERT_REQUESTED: {
      return {
        ...state,
        status: EDIT_ALERT_REQUESTED
      };
    }
    case EDIT_ALERT_SUCCESSFULL: {
      return {
        ...state,
        status: EDIT_ALERT_SUCCESSFULL,
        alert: action.alert
      };
    }
    case EDIT_ALERT_ERRORED: {
      return {
        ...state,
        status: EDIT_ALERT_ERRORED,
        error: action.error
      };
    }
    case DELETE_ALERT_REQUESTED: {
      return {
        ...state,
        status: DELETE_ALERT_REQUESTED
      };
    }
    case DELETE_ALERT_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_ALERT_SUCCESSFULL,
        alert: action.alert
      };
    }
    case DELETE_ALERT_ERRORED: {
      return {
        ...state,
        status: DELETE_ALERT_ERRORED,
        error: action.error
      };
    }
    case UPDATE_PERSON_ID_REQUESTED: {
      return {
        ...state,
        status: UPDATE_PERSON_ID_REQUESTED
      };
    }
    case GET_USER_AFIP_INFO_REQUESTED: {
      return {
        ...state,
        status: GET_USER_AFIP_INFO_REQUESTED
      };
    }
    case UPDATE_PERSON_ID_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_PERSON_ID_SUCCESSFULL
      };
    }
    case GET_USER_AFIP_INFO_SUCCESSFULL: {
      return {
        ...state,
        status: GET_USER_AFIP_INFO_SUCCESSFULL,
        afipInfo: action.afipInfo
      };
    }
    case UPDATE_PERSON_ID_ERRORED: {
      return {
        ...state,
        status: UPDATE_PERSON_ID_ERRORED,
        error: action.error
      };
    }
    case GET_USER_AFIP_INFO_ERRORED: {
      return {
        ...state,
        status: GET_USER_AFIP_INFO_ERRORED,
        error: action.error
      };
    }
    case GET_USER_PROFILES_REQUESTED: {
      return {
        ...state,
        status: GET_USER_PROFILES_REQUESTED
      };
    }
    case GET_USER_PROFILES_SUCCESSFUL: {
      return {
        ...state,
        status: GET_USER_PROFILES_SUCCESSFUL,
        userProfiles: action.userProfiles
      };
    }
    case GET_USER_PROFILES_ERRORED: {
      return {
        ...state,
        status: GET_USER_PROFILES_ERRORED,
        error: action.error
      };
    }
    case CREATE_USER_PROFILES_REQUESTED: {
      return {
        ...state,
        status: CREATE_USER_PROFILES_REQUESTED
      };
    }
    case CREATE_USER_PROFILES_SUCCESSFUL: {
      return {
        ...state,
        status: CREATE_USER_PROFILES_SUCCESSFUL
      };
    }
    case CREATE_USER_PROFILES_ERRORED: {
      return {
        ...state,
        status: CREATE_USER_PROFILES_ERRORED,
        error: action.error
      };
    }
    case CREATE_PAYMENT_METHOD_REQUESTED: {
      return {
        ...state,
        status: CREATE_PAYMENT_METHOD_REQUESTED,
        loading: false
      };
    }
    case CREATE_PAYMENT_METHOD_SUCCESSFUL: {
      return {
        ...state,
        status: CREATE_PAYMENT_METHOD_SUCCESSFUL,
        loading: true
      };
    }
    case CREATE_PAYMENT_METHOD_ERRORED: {
      let error = action.error?.data;
      if (
        action.error?.data?.error?.exception &&
        Array.isArray(action.error?.data?.error?.exception) &&
        action.error?.data?.error?.exception.length > 0
      ) {
        error = action.error.data.error.exception[0];
      } else if (action.error?.data?.error?.exception?.data) {
        error = action.error.data.error.exception.data;
      }
      return {
        ...state,
        status: CREATE_PAYMENT_METHOD_ERRORED,
        loading: false,
        error
      };
    }
    case VALIDATE_ACCOUNT_REQUESTED: {
      return {
        ...state,
        status: VALIDATE_ACCOUNT_REQUESTED,
        loading: false
      };
    }
    case VALIDATE_ACCOUNT_SUCCESSFUL: {
      return {
        ...state,
        status: VALIDATE_ACCOUNT_SUCCESSFUL,
        loading: true
      };
    }
    case VALIDATE_ACCOUNT_ERRORED: {
      return {
        ...state,
        status: VALIDATE_ACCOUNT_ERRORED,
        error: action.error,
        loading: false
      };
    }
    case GET_PAYMENT_METHODS_REQUESTED: {
      return {
        ...state,
        status: GET_PAYMENT_METHODS_REQUESTED,
        loading: true
      };
    }
    case GET_PAYMENT_METHODS_SUCCESSFUL: {
      return {
        ...state,
        status: GET_PAYMENT_METHODS_SUCCESSFUL,
        paymentMethods: action.paymentMethods,
        loading: false
      };
    }
    case GET_PAYMENT_METHODS_ERRORED: {
      return {
        ...state,
        status: GET_PAYMENT_METHODS_ERRORED,
        error: action.error,
        loading: false
      };
    }
    case OPEN_SHOPPING_CART_PREVIEW: {
      return {
        ...state,
        shoppingCartPreview:
          state.shoppingCartPreview.indexOf(action.path) === -1
            ? [...state.shoppingCartPreview, action.path]
            : state.shoppingCartPreview
      };
    }
    case CLOSE_SHOPPING_CART_PREVIEW: {
      return {
        ...state,
        shoppingCartPreview: state.shoppingCartPreview.filter(
          path => path !== action.path
        )
      };
    }
    default:
      return state;
  }
}
