import { makeStyles } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as WaterCounter } from '../../images/water-counter.svg';
import './meter.scss';
import useSavedWaterCounter from '../useSavedWaterCounter';

const SustainabilityMeter = () => {
  const classes = useStyles();
  const [tilesStyle, setTilesStyle] = useState('tiles');
  const counterRef = useRef();

  const [
    totalWater,
    hourlyWater,
    waterToAdd,
    counterWater,
    setCounterWater,
    SECONDS
  ] = useSavedWaterCounter(new Date().getTime());

  useEffect(() => {
    if (totalWater !== 0 && totalWater !== 999999999) {
      setTilesStyle(classes.numberTile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalWater]);

  useEffect(() => {
    counterRef.current = setInterval(() => {
      setCounterWater(prev => prev + waterToAdd);
    }, 1000 * SECONDS);
    return () => {
      clearInterval(counterRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterToAdd]);

  useEffect(() => {
    if (counterWater >= hourlyWater + totalWater) {
      clearInterval(counterRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterWater]);

  return (
    <div className={classes.container}>
      <WaterCounter />
      <div className={classes.content}>
        <div className={classes.numberTileContainer}>
          {counterWater
            .toString()
            .split('')
            .map((number, index) => (
              <div key={index} className={tilesStyle}>
                {number}
              </div>
            ))}
        </div>
        <div className={classes.text}>
          Litros de agua ahorrados gracias a tus compras
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '300px',
    height: '75px',
    marginTop: '8px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      height: '100%'
    },
    [`@media (max-width:447px)`]: {
      width: '96%'
    }
  },
  content: {
    position: 'absolute',
    width: '300px',
    height: '75px',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      alignItems: 'center'
    }
  },
  numberTileContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '25%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%'
    }
  },
  numberTile: {
    height: '30px',
    width: '20px',
    backgroundColor: '#FFFFFF',
    color: '#29abe2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    fontSize: '25px',
    fontWeight: '1000',
    margin: '1px'
  },
  text: {
    marginLeft: '25%',
    marginTop: '4px',
    fontWeight: '1000',
    fontSize: '12px',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      textAlign: 'center'
    }
  }
}));

export default SustainabilityMeter;
