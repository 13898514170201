import { Checkbox, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

const RTVSubCategoriesSelect = ({
  options,
  icon,
  checkedIcon,
  values,
  handleChange
}) => {
  const handleOptionChange = (option, isChecked) => {
    let newValues = [...values];
    if (isChecked) {
      newValues = [...newValues, option, ...option.sub_categories];
    } else {
      newValues = newValues.filter(
        value =>
          value.id !== option.id &&
          !option.sub_categories.some(subOption => subOption.id === value.id)
      );
    }
    handleChange(newValues, 'interestedIn');
  };

  const handleSubOptionChange = (updatedValues, reason) => {
    if (reason === 'remove-option') {
      let flatValues = updatedValues
        .map(value => {
          if (value.sub_categories) {
            return value.sub_categories.map(v => v);
          } else {
            return value;
          }
        })
        .flat();
      let singleValues = flatValues.filter(
        (value, index, arr) => arr.indexOf(value) === arr.lastIndexOf(value)
      );
      let newValues = updatedValues.filter(
        value => !singleValues.includes(value)
      );
      handleChange(newValues, 'interestedIn');
    }
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      options={options}
      disablePortal={true}
      getOptionLabel={option => option?.name}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <FormControlLabel
            control={
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                onChange={event =>
                  handleOptionChange(option, event.target.checked)
                }
              />
            }
            label={option.name}
          />
          {option.sub_categories && selected && (
            <div style={{ marginLeft: 30 }}>
              {option.sub_categories.map(subOption => (
                <FormControlLabel
                  key={subOption.id}
                  control={
                    <Checkbox
                      checked={values.some(value => value.id === subOption.id)}
                      onChange={event => {
                        const newValues = event.target.checked
                          ? [...values, subOption]
                          : values.filter(value => value.id !== subOption.id);
                        handleChange(newValues, 'interestedIn');
                      }}
                    />
                  }
                  label={subOption.name}
                />
              ))}
            </div>
          )}
        </React.Fragment>
      )}
      value={values}
      onChange={(_, values, reason) => {
        handleChange(values, 'interestedIn');
        handleSubOptionChange(values, reason);
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Las categorías que te interesan"
        />
      )}
    />
  );
};

export default RTVSubCategoriesSelect;
